export const custNotification = [
    {notType: 17, displayName: 'Credit Card Receipt', displayTextDefault: false, notGroup: 'Order Confirmations and Receipts', orderDispl: 1},
    {notType: 18, displayName: 'Order Confirmation', displayTextDefault: false, notGroup: 'Order Confirmations and Receipts', orderDispl: 2},

    {notType: 2, displayName: 'Quotes', displayTextDefault: true, notGroup: 'Order Changes', orderDispl: 3},
    {notType: 7, displayName: 'Backorder / Est. Ship Date', displayTextDefault: true, notGroup: 'Order Changes', orderDispl: 4},
    {notType: 16, displayName: 'Order Changed', displayTextDefault: true, notGroup: 'Order Changes', orderDispl: 5},
    {notType: 24, displayName: 'Service Report Available', displayTextDefault: true, notGroup: 'Order Changes', orderDispl: 6},

    {notType: 3, displayName: 'Submitted for Approval', displayTextDefault: true, notGroup: 'Approvals', orderDispl: 7},
    {notType: 19, displayName: 'Awaiting PO', displayTextDefault: true, notGroup: 'Approvals', orderDispl: 8},
    {notType: 5, displayName: 'Order Approved', displayTextDefault: true, notGroup: 'Approvals', orderDispl: 9},
    {notType: 4, displayName: 'Order Rejected', displayTextDefault: true, notGroup: 'Approvals', orderDispl: 10},

    {notType: 6, displayName: 'Shipped', displayTextDefault: true, notGroup: 'Tracking', orderDispl: 11},
    {notType: 15, displayName: 'Delivered', displayTextDefault: true, notGroup: 'Tracking', orderDispl: 12},
    {notType: 27, displayName: 'Arriving Today', displayTextDefault: true, notGroup: 'Tracking', orderDispl: 13},

    {notType: 20, displayName: 'Shopping Cart Reminder', displayTextDefault: true, notGroup: 'Reminders', orderDispl: 14},
    {notType: 12, displayName: 'Returns', displayTextDefault: true, notGroup: 'Reminders', orderDispl: 15},
    {notType: 11, displayName: 'Exchanges', displayTextDefault: true, notGroup: 'Reminders', orderDispl: 16},
    {notType: 25, displayName: 'Approvals', displayTextDefault: true, notGroup: 'Reminders', orderDispl: 17},
    {notType: 26, displayName: 'Repairs', displayTextDefault: true, notGroup: 'Reminders', orderDispl: 18},

    // the Service Requests notifications are on SalesForce's side, hence the negative type ids
    {notType: -1, displayName: 'Service Reminders', displayTextDefault: true, notGroup: 'Service Requests', isRSA: true, orderDispl: 19},
    {notType: -2, displayName: 'Technician Status', displayTextDefault: true, notGroup: 'Service Requests', isRSA: true, orderDispl: 20},
    {notType: -3, displayName: 'Exception Updates', displayTextDefault: true, notGroup: 'Service Requests', isRSA: true, orderDispl: 21},
    {notType: -4, displayName: 'FSR Notification', displayTextDefault: true, notGroup: 'Service Requests', isRSA: true, orderDispl: 22},
];
