import axios from 'axios';
import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';
import $ from 'jquery';

import {Checkbox, Button, Popup, Dropdown, TextField} from '@partssourceinc/react-ui-core';
import PageMetaData from 'components/PageMetaData';
import BreadCrumbs from 'components/BreadCrumbs';
import {custNotification} from 'data/CustomerNotificationDefinition';
import {timeZones,ianaTime} from 'data/TimeZonesData';
import {Desktop, Phone, PhoneBig, Tablet} from 'responsive';
import * as UserStore from 'stores/User';
import moment from 'moment-timezone';

import 'less/settings/notification.less';
import 'less/settings/settingslanding.less';
import {getFormattedPhoneNum, stripAwayPhoneNumberSymbols, stripAwayPhoneNumberCode, logEvent} from 'utility';
import {validateStrippedPhoneNumber} from '/validation';

@withRouter
@connect((state) => ({user: state.user}))
export default class MyAccountNotifications extends Component {
    static propTypes = {
        user: UserStore.StateShape,
    };

    constructor(props) {
        super(props);

        this.state = {
            userId: props.user.info.contactId,
            showErrors: false,
            defaultEmail: props.user.info.email,
            mobileState: null,
            isMobileSet: false,
            selectedTabIndex: null,
            displayAddMobile: false,
            isMobileVerified: false,
            invalidCode: false,
            codeSent: '',
            invalidPhone: false,
            mobileNumber: '',
            selectedTimeZone: null,
            email: '',
            currentGroup: '',
            loaded: false,
            visibleSections: [],
            approveOrdersForOthers: false,
            timeZones: timeZones,
            ianaTime: ianaTime,
            notifications: _.groupBy(custNotification, n => n.notGroup),
            showInvalidExtEmail: false,
            showSuccessAll: false,
            notValidForText: false,
            areRSANotificationsAvailable: false,
        };

        this.validatePhone = this.validatePhone.bind(this);
        this.validateVCode = this.validateVCode.bind(this);
        this.onSettingChange = this.onSettingChange.bind(this);
        this.onSaveNotifications = this.onSaveNotifications.bind(this);
    }
    
    componentDidMount() {
        const {user: {settings: {approver, enableOnSiteService}}, user} = this.props;

        axios.get(`/ShoppingService/api/v1/notification/${enableOnSiteService}`).then(x => {
            const {data: {notifications, rsaNotifications, mobileNumber, isMobileVerified, isOptedOut, verificationCode, timeZoneId, email}} = x;

            const notificationsObj = _.groupBy(custNotification.map(cn =>
                _.merge({}, cn, {emailActive: 0, textActive: 0}, _.find(notifications, {notificationType: cn.notType}))), g => g.notGroup);

            let areRSANotificationsAvailable = false;
            if (rsaNotifications) {
                rsaNotifications.map((item) => {
                    const ind = notificationsObj['Service Requests'].findIndex(i => i.displayName === item.type);
                    notificationsObj['Service Requests'][ind].emailActive = item.email === true ? 1 : 0;
                });
                areRSANotificationsAvailable = true;
            }

            this.setState({
                mobileNumber: mobileNumber,
                isOptedOut: isOptedOut,
                email: email,
                verificationCode: verificationCode,
                approveOrdersForOthers: approver,
                notifications: notificationsObj,
                selectedTimeZone: timeZones.filter(k => k.zoneId === timeZoneId)[0],
                mobileState: !mobileNumber ? 'undefined' : isMobileVerified ? 'verified' : isOptedOut ? 'optOut' : 'entered',
                defaultEmail: user.email || email,
                areRSANotificationsAvailable: areRSANotificationsAvailable,
            });
        });
    }
    
    reload = () => {
        const {history} = this.props;

        history.push('settings')
    }

    sendVCode() {
        const {mobileNumber} = this.state;
        const ianaZone = moment.tz.guess();
        const ianaZoneName = ianaTime.find(IanaTime => IanaTime.type === ianaZone);
        const windowsTime = ianaZoneName ? timeZones.find(TimeZones => TimeZones.zoneId === ianaZoneName.mapZone) : '';
        this.setState({selectedTimeZone: windowsTime});
        if (mobileNumber === null) return false;
        axios.post('/ShoppingService/api/v1/notification/verify/' + mobileNumber).then(response => {
            if (response.data.error === 'Verified') {
                this.setState({mobileState: 'verified'});
            } else {
                (response.data.success || response.data) ? this.setState({mobileState: 'verify'}) : this.setState({invalidPhone: true})
            }
        });
    }

    validateVCode() {
        const codeEnt = $('#verificationCode').val();
        if (!codeEnt || !codeEnt.match(/^[0-9]{4}$/)) {
            this.setState({invalidCode: true});
            return;
        }
        
        const {mobileNumber} = this.state;
        axios.post(`/ShoppingService/api/v1/notification/confirm/${mobileNumber}/${codeEnt}`)
            .then((r) => {
                if (r.data.success)
                    this.setState({mobileState: 'verified', isMobileVerified: true});
                else
                    this.setState({mobileState: 'verify', isMobileVerified: false, invalidCode: true});
                return true;
            });
    }

    validatePhone(e) {
        const {mobileNumber} = this.state;
        let number = e && e.target && e.target.value;

        if (!number) {
            this.setState({isMobileVerified: false, invalidPhone: false});
            return;
        }

        let numberStripped = stripAwayPhoneNumberSymbols(number);
        let numberWithoutCode = stripAwayPhoneNumberCode(numberStripped);

        if (!numberWithoutCode) {
            // the number is incomplete/wrong
            this.setState({isMobileVerified: false, invalidPhone: true, mobileNumber: numberStripped});
            return;
        }

        let isValid = validateStrippedPhoneNumber(numberWithoutCode);

        if (isValid) {
            if (mobileNumber !== numberStripped) {
                // the number is valid, but needs to be verified
                this.setState({isMobileVerified: false, invalidPhone: false, mobileNumber: numberStripped});
                return;
            }
        } else {
            // the number is invalid
            this.setState({invalidPhone: true, mobileState: 'edit', mobileNumber: numberStripped});
            return;
        }
    }

    onSettingChange(item, e) {
        const {notValidForText} = this.state;
        let subType = e.id.split('_')[0];
        switch (subType) {
            case 'chk1':
                item.emailActive = e.checked ? 1 : 0;
                break;
            case 'chk2':
                item.textActive = e.checked ? 1 : 0;
                break;
        }
        if (notValidForText) {
            const needsValidation = this.validateForTextSMS();
            if (!needsValidation) {
                this.setState({notValidForText: false});
            }

        }
        this.setState(this.state.notifications);
    }
    
    onSettingEmailsChange(item, e) {
        const {showInvalidExtEmail, invalidField} = this.state;
        
        if (e.target.defaultValue === '' || this.validateEmailAddress(e)) {
            item.extEmail = e.target.defaultValue;
            if ((showInvalidExtEmail) && invalidField === e.target.attributes.id.nodeValue)
                this.setState({showInvalidExtEmail: false, invalidField: null, showSuccessAll: false});
        } else
            this.setState({showInvalidExtEmail: true, invalidField: e.target.attributes.id.nodeValue, showSuccessAll: false});
    }
    
    validateEmailAddress(e) {
        let addr = e.target.defaultValue.split(/[,;]+/);
        if (addr === 'undefined' || addr.length === 0) return true;
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let partsSourceRegex = /@partssource.com\s*$/;

        for (let i = 0; i < addr.length; i++) {
            let em = addr[i].trim();
            if (em !== '' && (!re.test(em) || partsSourceRegex.test(em.toLowerCase())))
                return false;
        }
        return true;
    }
    
    onSaveNotifications = () => {
        const {notifications, codeEnt, defaultEmail, mobileNumber, isMobileVerified, selectedTimeZone, mobileState, userId} = this.state;
        let mobileNumberToSave = null;
        if (mobileNumber && (selectedTimeZone === null || selectedTimeZone === undefined)) {
            this.setState({showErrors: true});
            return false;
        } else {
            // do not pass the mobileNumber to newSet if the mobileNumber was not verified
            if (mobileNumber && (isMobileVerified || mobileState === 'verified')) {
                mobileNumberToSave = mobileNumber;
            }
        }
        
        const notificationsWithoutRSA = {...notifications};
        delete notificationsWithoutRSA['Service Requests'];

        const notificationsRSA = notifications['Service Requests'].map(x => {
            return {
                Type: x.displayName,
                Email: x.emailActive === 1 ? true : false,
            }
        });
        
        const mappedNotes = Object.keys(notificationsWithoutRSA).map(key =>
            _.map(notificationsWithoutRSA[key], function (e) {
                return {ExtEmail: (e.extEmail || ''), EmailActive: e.emailActive, TextActive: e.textActive, NotificationType: e.notType};
            }));
        const newNotes = Array.prototype.concat(...mappedNotes);
        const newSet = {
            VerificationCode: codeEnt,
            Email: defaultEmail,
            MobileNumber: mobileNumberToSave,
            IsOptedOut: (mobileNumberToSave === ''),
            TimeZoneId: (selectedTimeZone) ? selectedTimeZone.zoneId : '',
            IsMobileVerified: isMobileVerified,
            Notifications: newNotes,
            RSANotifications: notificationsRSA,
        };
        axios.post('/ShoppingService/api/v1/notification/save', newSet).then(() => {
            this.setState({showSuccessAll: true, showErrors: false, showInvalidExtEmail: false});
        });
        logEvent('Notifications Edit', {UserId: userId});
    }
    
    closeAddPopup() {
        const {mobileNumber, isMobileVerified, invalidPhone} = this.state;
        if (isMobileVerified)
            this.setState({mobileState: 'verified'});
        else
        if ((mobileNumber) && mobileNumber.length > 0 && !invalidPhone)
            this.setState({mobileState: 'entered'});
        else
            this.setState({mobileState: 'undefined'});
    }
    
    closeCancelConfPopup(e) {
        const {isMobileVerified, mobileNumber} = this.state;
        if (isMobileVerified && (mobileNumber))
            this.setState({mobileState: 'verified'});
        else {
            this.setState({mobileState: 'edit'});
        }
    }

    closeConfPopup(e) {
        const {isMobileVerified, mobileNumber} = this.state;
        this.validateVCode();
        if (isMobileVerified && (mobileNumber))
            this.setState({mobileState: 'verified'});
        else {
            e.preventDefault();
            this.setState({mobileState: 'verify'});
        }
    }
    closeDeleteMobilePopup() {
        this.setState({mobileState: 'verified'});        
    }
    deleteMobileNumber() {
        let {mobileNumber, notifications, userId} = this.state;
        axios.post(`/ShoppingService/api/v1/notification/delete/${mobileNumber}`)
            .then((r) => {
                if (r.data.success) {
                    let keys = Object.keys(notifications);
                    for (let i = 0; i < keys.length; i++) {
                        let elements = notifications[keys[i]].filter(x => x.textActive === 1);
                        if (elements.length > 0) {
                            for (let n = 0; n < elements.length; n++) {
                                elements[n].textActive = 0;
                            }
                        }
                    }
                    this.setState({notifications, mobileNumber: null, isMobileVerified: false, mobileState: 'undefined'});
                }
            });
        logEvent('Notifications Edit', {UserId: userId}); 
    }
    handleTabSelected(index) {
        this.setState({selectedTabIndex: index});
    }
    
    handleChangeTimeZone = (event) => {
        this.setState({selectedTimeZone: timeZones.find(t => t.zoneId === event.target.value)});
    };
    
    renderAddMobileNumberPopup() {
        const {mobileNumber, invalidPhone} = this.state;
        let formattedPhoneNumber = (!(_.isNil(mobileNumber)) && mobileNumber != '') ? getFormattedPhoneNum(stripAwayPhoneNumberCode(mobileNumber)) : null;
        let noPhoneNumberEntered = !formattedPhoneNumber || formattedPhoneNumber.length <= 3; // the formattedPhoneNumber is set to "1 (" when empty

        return (<Popup show={true} confirmText="Get Verification Code"
            cancelText="Cancel"
            hideButtons={false}
            onCancel={() => this.closeAddPopup()}
            className="add-group-dialog"
            onConfirm={() => this.sendVCode()}
            disableConfirm={invalidPhone || noPhoneNumberEntered}>
            
            <div>
                <h1>Add Mobile Phone Number (SMS)</h1>
                <span><h3>You will typically receive SMS text notifications between 8am and 9pm Eastern. Frequency is based on user orders. 
                        Phone number verification is required. Message and data rates may apply. For additional information, see the </h3>
                <a href={'/policies/termsandconditionofsale'} className="non-chevron-link" target={'_blank'} rel="noreferrer">Terms & Conditions of Sale</a>.</span>
                <TextField id="mobileNumber" 
                    onChange={e => this.validatePhone(e)} 
                    label="Mobile Phone" 
                    placeholder="Mobile Phone" 
                    tabIndex={0} 
                    text={formattedPhoneNumber} 
                    showErrorMessage={invalidPhone || noPhoneNumberEntered} />
            </div>
        </Popup>);
    }

    renderConfirmMobileNumberPopup() {
        const {invalidCode} = this.state;
        return (<Popup confirmText="Save"
            show={true} 
            cancelText="Cancel"
            hideButtons={false}
            onCancel={() => this.closeCancelConfPopup()}
            className="add-group-dialog"
            onConfirm={(e) => this.closeConfPopup(e)}>
           
            <div>
                <h1>Verify Your Number</h1>
                <h2>Enter your four-digit SMS verification code below.</h2>
                <TextField id="verificationCode" label="Verification Code" placeholder="Verification Code"
                    tabIndex={0} showErrorMessage={invalidCode} />
                <div 
                    onClick={() => this.sendVCode()} 
                    style={{color: '#7e7e7e', textDecoration: 'underline', cursor: 'pointer', marginTop: '10px'}}
                >Didn't get it? Send a new code</div> 
            </div>
        </Popup>);
    }
    renderDeleteMobileNumberPopup() {
        return (<Popup confirmText="Save"
            show={true} 
            cancelText="Cancel"
            hideButtons={false}
            onCancel={() => this.closeDeleteMobilePopup()}
            className="add-group-dialog"
            onConfirm={() => this.deleteMobileNumber()}>           
            <div>
                <h1>Delete Mobile Phone</h1>
                <h3>Are you sure you wish to delete your mobile phone number?</h3>
            </div>
        </Popup>);
    }
    renderMobileState() {
        const {mobileState, mobileNumber} = this.state;
        let formattedPhoneNumber = (!(_.isNil(mobileNumber)) && mobileNumber != '') ? getFormattedPhoneNum(stripAwayPhoneNumberCode(mobileNumber)) : null;
        let noPhoneNumberEntered = !formattedPhoneNumber || formattedPhoneNumber.length <= 3; // the formattedPhoneNumber is set to "1 (" when empty

        switch (mobileState) {
            case 'undefined':
                return (<div className="non-chevron-link" onClick={() => this.setState({mobileState: 'edit'})}>
                            Add Mobile Number
                </div>);

            case 'edit':
                return this.renderAddMobileNumberPopup();
            case 'delete':
                return this.renderDeleteMobileNumberPopup();
            case 'verify':
                return this.renderConfirmMobileNumberPopup();
            case 'optOut':
            case 'entered':
                return (<div>
                    <span>Mobile Phone: {noPhoneNumberEntered ? '' : formattedPhoneNumber}</span>
                    <span className="non-chevron-link" onClick={() => this.setState({mobileState: 'edit'})}>Verify</span>                    
                </div>);
            case 'verified':
                return (<div>
                    <span>Mobile Phone: {noPhoneNumberEntered ? '' : formattedPhoneNumber}</span>
                    <span className="non-chevron-link" onClick={() => this.setState({mobileState: 'edit'})}>Edit</span>  
                    <span className="non-chevron-link" onClick={() => this.setState({mobileState: 'delete'})}>Delete</span>
                </div>);
        }
    }

    renderTimeZone() {
     
        const {mobileState, timeZones, selectedTimeZone, showErrors} = this.state;
        let selectedTimeZoneVal = (selectedTimeZone) ? selectedTimeZone.zoneId : '';
        switch (mobileState) {            
            case 'entered' :
            case 'verified' :
                return (<div>                
                    <Dropdown label="Time Zone (required)" onChange={this.handleChangeTimeZone}
                        options={timeZones} selectedValue={selectedTimeZoneVal} suppressBlankOption={false} valueField="zoneId" textField="zoneName" showErrorMessage={showErrors} />
                </div>);                                                 
        }
    }

    renderNotificationGroup(groupName, items) {
        const {approveOrdersForOthers, showInvalidExtEmail, invalidField, areRSANotificationsAvailable} = this.state;
        const {user: {settings: {enableOnSiteService}}} = this.props;
        
        return (
            <React.Fragment>
                {(groupName !== 'Service Requests' || (groupName === 'Service Requests' && enableOnSiteService && areRSANotificationsAvailable)) && <React.Fragment>
                    <div className="row">
                        <div className="notificationSeperator" />
                    </div>
                    <div className="row" style={{width: '100',paddingTop: '10px'}} >
                        <div key={groupName} style={{lineHeight: '50px', marginRight: '35px'}} className="col-md-12 groupName">{groupName}
                        </div>
                    </div>
                </React.Fragment>}
                {(items || []).map(item => (((item.notType === 3 || item.notType === 25) && !approveOrdersForOthers) || (item.isRSA && (!enableOnSiteService || !areRSANotificationsAvailable))) ? null :
                    <div key={item.notType} className="row" style={{lineHeight: '50px'}}>
                        <div className="col-md-4" style={{fontSize: '16px', fontWeight: '600'}}>{item.displayName}</div>
                        <div className="col-md-1"><Checkbox id={'chk1_' + item.notType} onChange={(e) => this.onSettingChange(item, e)} className="checkbox" checked={item.emailActive} disabled={item.isReadOnly} /></div>
                        <div className="col-md-1">{item.displayTextDefault && !item.isRSA ? <Checkbox id={'chk2_' + item.notType} onChange={(e) => this.onSettingChange(item,e)} className="checkbox" checked={item.textActive} /> : <span>&nbsp;</span>}</div>
                        <div className="col-md-6 text-mobile-no-padding">{!item.isRSA ? <TextField className="textFieldInputNotific" id={'addEmails_' + item.notType} label="Add Recipient(s)" placeholder="Add Recipient(s)" text={item.extEmail} onBlur={(e) => this.onSettingEmailsChange(item, e)} showErrorMessage={showInvalidExtEmail && invalidField === ('addEmails_' + item.notType)} /> : <div className="textFieldInputNotific" style={{height: '48px'}} />}</div>
                    </div>)}
            </React.Fragment>
        );
    }

    renderNotificationGroupMobile(groupName, items) {
        const {approveOrdersForOthers, visibleSections, showInvalidExtEmail, invalidField, areRSANotificationsAvailable} = this.state;
        const {user: {settings: {enableOnSiteService}}} = this.props;

        return (
            <div key="tabs" className={`notification-tabs`}>
                {(groupName !== 'Service Requests' || (groupName === 'Service Requests' && enableOnSiteService && areRSANotificationsAvailable)) && <div className="notification-tabs_header ps-hidden-lg ps-hidden-md" onClick={(e) => this.handleSectionSelected(e, groupName)}>
                    <div className={`notification-tabs_tab lbl-bold tab-section-closed ${
                        visibleSections.includes({groupName}) ? 'notification-tabs_tab--active' : ''}`} >                                                            
                        {groupName}
                    </div>
                </div>}
                {visibleSections.includes(groupName) ? (
                    <div className="notification-tabs_content ps-hidden-lg ps-hidden-md">
                        {(items || []).map(item => (((item.notType === 3 || item.notType === 25) && !approveOrdersForOthers) || (item.isRSA && (!enableOnSiteService && !areRSANotificationsAvailable))) ? null :
                            <div className="notification-item-details" key={item.notType} style={{lineHeight: '50px'}}>
                                <div className="notification-item notification-item-label" style={{paddingLeft: '0px', paddingRight: '2px', fontSize: '16px', fontWeight: '600'}}>{item.displayName}</div>
                                <div className="notification-item notification-item-check"><div style={{fontWeight: 'bold'}}>Email</div><Checkbox id={'chk1_' + item.notType} onChange={(e) => this.onSettingChange(item, e)} className="checkbox" checked={item.emailActive} disabled={item.isReadOnly} /></div>
                                <div className="notification-item notification-item-check">{item.displayTextDefault && !item.isRSA ? <div><div style={{fontWeight: 'bold'}}>Text</div><Checkbox id={'chk2_' + item.notType} onChange={(e) => this.onSettingChange(item,e)} className="checkbox" checked={item.textActive} /></div> : <span>&nbsp;</span>}</div>
                                <div className="notification-item notification-item-text">{!item.isRSA ? <TextField className="textFieldInputNotific" id={'addEmails_' + item.notType} label="Add Recipient(s)" placeholder="Add Recipient(s)" text={item.extEmail} onBlur={(e) => this.onSettingEmailsChange(item, e)} showErrorMessage={showInvalidExtEmail && invalidField === ('addEmails_' + item.notType)} /> : <div className="textFieldInputNotific" style={{height: '48px'}} />}</div>
                            </div>)}
                    </div>     
                ) : null}                                                               
            </div>
        );         
        
    }

    handleSectionSelected(element, index) {
        const {visibleSections} = this.state;

        let currEl = element && element.currentTarget && element.currentTarget.childNodes && element.currentTarget.childNodes[0];
        if (currEl != null) {
            this.setNotificationSectionArrowIconUponClick(currEl);
        }

        if (visibleSections.includes(index)) {
            this.setState({
                visibleSections: visibleSections.filter(s => s !== index),
            });
        } else {
            visibleSections.push(index);
            this.setState({visibleSections: visibleSections});
        }
    }

    setNotificationSectionArrowIconUponClick(element) {
        if (element.classList.contains('tab-section-closed')) {
            // the curr section is expanded - set arrow down icon
            element.classList.remove('tab-section-closed');
            element.classList.add('tab-section-opened');
        } else {
            // the curr section was already expanded and we want to close it - set arrow right icon
            element.classList.remove('tab-section-opened');
            element.classList.add('tab-section-closed');
        }
    }

    renderNotificationGroupOld(groupName, items) {
        const {approveOrdersForOthers, showInvalidExtEmail, invalidField} = this.state;
        return (
            <tbody>
                <tr key={groupName} className="groupName"><td colSpan="4" ><span>{groupName}</span></td></tr>
                {(items || []).map(item => ((item.notType === 3 || item.notType === 25) && !approveOrdersForOthers) ? null : <tr key={item.notType}>
                    <td>{item.displayName}</td>
                    <td><Checkbox id={'chk1_' + item.notType} onChange={(e) => this.onSettingChange(item, e)} className="checkbox" checked={item.emailActive} disabled={item.isReadOnly} /></td>
                    <td>{item.displayTextDefault ? <Checkbox id={'chk2_' + item.notType} onChange={(e) => this.onSettingChange(item,e)} className="checkbox" checked={item.textActive} /> : <span />}</td>
                    <td><TextField id={'addEmails_' + item.notType} label="Add Recipient(s)" placeholder="Add Recipient(s)" text={item.extEmail} onBlur={(e) => this.onSettingEmailsChange(item, e)} showErrorMessage={showInvalidExtEmail && invalidField === ('addEmails_' + item.notType)} /></td>
                </tr>)}
            </tbody>
        );
    }

    render() {
        let {defaultEmail, showErrors, notifications, showSuccessAll, notValidForText} = this.state;

        return (<div className="settings-landing">
            <PageMetaData 
                title="My Account"
                pageType="other"
                trackAnalytics={true} />
            <BreadCrumbs />
            <div className="settings-title">
                <div className="space-nav"><i className="fa fa-chevron-left" />  <NavLink className="grid-header-link" to="/settings">Back To Settings</NavLink></div>
            </div>
            <h1>Notifications</h1>
            <div id="notification-default-row">

                <div className="row">
                    <div className="col-md-4">
                        <div className="default-email ">
                            <h2>Default Email</h2>
                            <TextField id="defaultEmail" label="Email Address" placeholder="Email Address" showErrorMessage={showErrors && defaultEmail === ''} text={defaultEmail} />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="default-mobile">
                            <h2>Mobile Phone (SMS)</h2>
                            {this.renderMobileState()}                   
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="default-mobile">                        
                            {this.renderTimeZone()}                   
                        </div>
                    </div>
                </div>                                   
                <div className="row ps-hidden-xs ps-hidden-sm" style={{lineHeight: '50px'}}>
                    <div className="col-md-4 firstCol">Notification Type</div>
                    <div className="col-md-1 secCol">Email</div>
                    <div className="col-md-1 thirdCol">Text</div>
                    <div className="col-md-6 fourthCol">Send to Additional / Alternate Email</div>
                </div>
                <div>
                    <Desktop>
                        {Object.keys(notifications).map(key => this.renderNotificationGroup(key, notifications[key]))}
                    </Desktop>
                    <Tablet>
                        {Object.keys(notifications).map(key => this.renderNotificationGroup(key, notifications[key]))}
                    </Tablet>
                    <Phone>
                        <div style={{marginTop: '20px'}}>
                            {Object.keys(notifications).map(key => this.renderNotificationGroupMobile(key, notifications[key]))}
                        </div>
                    </Phone>
                    <PhoneBig>
                        <div style={{marginTop: '20px'}}>
                            {Object.keys(notifications).map(key => this.renderNotificationGroupMobile(key, notifications[key]))}
                        </div>
                    </PhoneBig>
                </div>               

                <div className="modal-footer" style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column'}}>
                    {notValidForText && <p style={{color: 'red'}}>A mobile phone number is required for text message notifications</p>}
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <a onClick={this.reload}>CANCEL</a>
                        <Button className="primary" onClick={this.onSaveNotifications} >SAVE <i className="fa fa-check pull-right" aria-hidden="true" style={{display: (showSuccessAll) ? 'block' : 'none'}} /></Button>
                    </div>
                </div>
                
            </div>
        </div>);
    }
}
